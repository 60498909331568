<template>
  <!-- condition prevents showing blurred-meeting before redirect -->
  <div class="flex flex-grow" v-if="!!id">
    <blurred-meeting> </blurred-meeting>
  </div>
</template>

<script>
import BlurredMeeting from './BlurredMeeting.vue';

export default {
  name: 'NotFound',
  components: {
    BlurredMeeting,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      delay: 1000,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$gAuth.isAuthorized;
    },
  },
  mounted() {
    // no id == not existing URL
    if (!this.id && !this.isAuthenticated) {
      this.$router.replace({ name: 'signin' });
      return;
    }

    if (!this.id) {
      // we could apply some delay here
      this.$snack("Oops - we couldn't find that page!");
      // this.$router.replace({ name: 'meetings' });
      return;
    }

    // notification bar delay
    setTimeout(this.showDelayedNotification(), this.delay);
  },
  methods: {
    showDelayedNotification() {
      if (this.$gAuth.isAuthorized) {
        this.$notification(
          'Oops - meeting not found! Check url or poke host for access.',
          {
            closable: false,
            type: 'error',
            errorMsg: 'notfound-signedin',
          }
        );
      } else {
        this.$notification(
          [
            {
              text: 'Oops - meeting not found! Check url, or ',
            },
            { link: { text: 'sign in to Meetric', to: 'signin' } },
            { text: ' to take notes for any meeting.' },
          ],
          {
            closable: false,
            type: 'error',
            errorMsg: 'notfound-signedout',
          }
        );
      }
    },
  },
};
</script>
